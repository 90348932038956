<template>
  <div>
    <LoadingContainer :is-loading="isLoadingGiftActions && !isFiltering">
      <div class="card">
        <GiftActionsHeader
          :total="paginationParams.count"
          :selections="selections"
          :has-selections="hasSelections"
          title="Δώρα"
          @navigate="navigate"
        />
        <div class="card-content">
          <Filters
            :options="options"
            :is-loading="isFiltering"
            :filters="paginationParams.filters"
            general-label="Αναζήτηση με χρήση τίτλου"
            @navigate="navigate"
          />

          <NewPagination
            :items="items"
            :total="paginationParams.count"
            :current-page="paginationParams.page"
            :pages-count="paginationParams.pages"
            :items-per-page="paginationParams.size"
            :is-loading="isLoading"
            @navigate="navigate"
          />

          <div class="columns">
            <div class="column is-full">
              <LoadingContainer
                :is-loading="isFiltering"
                :is-opaque="true"
                class="table-wrapper"
              >
                <table
                  v-if="items.length"
                  class="table is-bordered is-striped is-marginless is-fullwidth"
                >
                  <thead>
                    <tr>
                      <th class="hidden-print">
                        <checkbox
                          v-model="selectedAll"
                          name="selected"
                          @input="selectAll"
                        />
                      </th>
                      <th>
                        <sortable-header
                          :sort-options="paginationParams.sort"
                          label="Τίτλος δωρου"
                          attribute="gift title"
                          @sort="sort"
                        />
                      </th>
                      <th>Ενεργό</th>
                      <th>URL</th>
                      <th>Ημερομηνία Εκκίνησης</th>
                      <th>Ημερομηνία Λήξης</th>
                      <th class="hidden-print">Ενέργειες</th>
                    </tr>
                  </thead>
                  <tbody>
                    <GiftAction
                      v-for="(item, index) in items"
                      :item="item"
                      :index="paginationParams.indexStartsFrom + index"
                      :key="item.id"
                      :selections="selections"
                      @askToDeleteItem="askToDeleteItem"
                      @changeItemActive="changeItemActive"
                    />
                  </tbody>
                </table>
                <h4 v-else class="title is-4 has-text-centered">
                  Δε βρέθηκαν Δώρα
                </h4>
              </LoadingContainer>
            </div>
          </div>

          <NewPagination
            v-if="items.length"
            :items="items"
            :total="paginationParams.count"
            :current-page="paginationParams.page"
            :pages-count="paginationParams.pages"
            :items-per-page="paginationParams.size"
            :is-loading="isLoading"
            @navigate="navigate"
          />
        </div>
      </div>

      <Confirmation
        :model-name="selectedItem.code"
        :is-open="isOpen"
        description="Είστε βέβαιος για τη διαγραφή αυτού του Δώρου;"
        @closeModal="closeModal"
      />
    </LoadingContainer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/utils/request';
import GiftActionsHeader from '@/views/Dashboard/views/Marketing/views/GiftActions/views/List/components/GiftActionsHeader';
import GiftAction from '@/views/Dashboard/views/Marketing/views/GiftActions/views/List/components/GiftAction';
import PaginatedList from '@/views/components/PaginatedList';

export default {
  components: {
    GiftActionsHeader,
    GiftAction,
  },
  extends: PaginatedList,
  metaInfo: {
    title: 'Δώρα',
  },
  data: () => ({
    offset: { top: 45 },
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
      {
        label: 'Προβολή',
        title: 'active',
        type: 'boolean',
        value: null,
      },
    ],
  }),

  computed: {
    ...mapGetters({
      items: 'giftActions/list/getData',
      paginationParams: 'giftActions/list/getPaginationParams',
      isLoadingGiftActions: 'giftActions/list/getIsLoading',
      isLoaded: 'giftActions/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.fetchGiftActionsPaginated(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router.replace({
              name: this.$route.name,
              query: this.filterParams({
                page: 1,
                size: 50,
                filters: {},
                sort: {},
                paginated: 1,
              }),
            });
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      fetchGiftActionsPaginated: 'giftActions/list/fetchGiftActionsPaginated',
    }),

    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router
          .push({
            name: this.$route.name,
            query: this.filterParams({ ...this.paginationParams, ...params }),
          })
          .catch(err => console.log(err));
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/giftActions/${this.selectedItem.id}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Το Δώρο διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async changeItemActive(selectedItem) {
      try {
        const isActive = !selectedItem.active;

        await request.put(`/giftActions/${selectedItem.id}/active`, {
          active: !!isActive,
        });

        await this.navigate({
          timestamp: new Date().getTime(),
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: isActive
            ? 'Το Δώρο δημοσιεύτηκε επιτυχώς!!'
            : 'Το Δώρο δεν προβάλλεται πλέον',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
